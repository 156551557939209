@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&family=PT+Sans+Narrow&display=swap');

@import "mixins";

/********************************************************************************/

$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color: white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;
$button-text-color: #fff;

$custom-primary: #ad7140;
$custom-secondary:#aa4f43;

$theme-color-1: #ad7140;
$theme-color-2: #aa4f43;
$theme-color-3: #c4a688;
$theme-color-4: #916948;
$theme-color-5: #856f5a;
$theme-color-6: #6e5e59;

$primary-color: #aa4f43;
$secondary-color: #c4a688;
$text-color-black: #231f20;
$text-color-redbrown: #aa4f43;
$muted-color: #909090;

$gradient-color-1: #ffffff;
$gradient-color-2: #aa4f435e;
$gradient-color-3: hsla(345, 6%, 13%, 0.5);

$lp-bg-color-1: $custom-primary;
$lp-bg-color-2: $custom-primary;
$lp-bg-color-3: $custom-secondary;
$lp-bg-color-4: $custom-secondary;

$shadow-offsets-top: 1 3 10 14 19;
$shadow-blurs-top: 1.5 5 10 14 19;
$shadow-opacities-top: 0.04 0.1 0.19 0.25 0.3;

$shadow-offsets-bottom: 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.04 0.1 0.2 0.22 0.22;

$logo-path: "../../logos/logo_mdn.jpeg";
$logo-path-mobile: "../../logos/logo_mdn.jpeg";

$lp-logo-path-pinned: "../../logos/logo_mdn.jpeg";
$lp-logo-path: "../../logos/logo_mdn.jpeg";

$topnav-background-img: url('/assets/logos/background.png');

$navbar-height: 100px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$footer-height: 50px;

$main-margin: 60px;

$main-margin-bottom: 10px;
$main-margin-bottom-xl: 10px;
$main-margin-bottom-lg: 10px;
$main-margin-bottom-sm: 10px;
$main-margin-top: 10px;

$sante-gradient: linear-gradient(
    225deg,
    #aa4f43 0%,
    #c43d4b 10%,
    #dc3545bf 20%,
    #c43d4b75 30%,
    #c43d4b2e 40%,
    rgba(255, 255, 255, 0) 100%
);
$sante-top-gradient: linear-gradient(
    225deg,
    rgba(236, 0, 140, 1) 0%,
    rgba(236, 0, 140, 0.8) 10%,
    rgba(236, 0, 140, 0.6) 20%,
    rgba(236, 0, 140, 0.4) 30%,
    rgba(236, 0, 140, 0.2) 40%,
    rgba(255, 255, 255, 0) 100%
);
$sante-max-gradient: linear-gradient(
    225deg,
    rgba(245, 130, 32, 1) 0%,
    rgba(245, 130, 32, 0.8) 10%,
    rgba(245, 130, 32, 0.6) 20%,
    rgba(245, 130, 32, 0.4) 30%,
    rgba(245, 130, 32, 0.2) 40%,
    rgba(255, 255, 255, 1) 100%
);
$sante-plus-gradient: linear-gradient(
    225deg,
    rgba(140, 198, 66, 1) 0%,
    rgba(140, 198, 66, 0.8) 10%,
    rgba(140, 198, 66, 0.6) 20%,
    rgba(140, 198, 66, 0.4) 30%,
    rgba(140, 198, 66, 0.2) 40%,
    rgba(255, 255, 255, 0) 100%
);

$p-font-size: 0.9rem;

$login-background-img: #A7392E;